@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    "DM Sans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  ::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    width: 1px;
    background: #d9d9d9;
    border-radius: 100vh;
  }

  ::-webkit-scrollbar-thumb {
    background: #d09560;
    border-radius: 100vh;
  }
}
